import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

const Pagination = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

    return (
        <nav className="pagination" role="navigation">
            <div className="container flex">
                {previousPagePath && <Link to={previousPagePath} rel="prev"><MdKeyboardArrowLeft /></Link>}
                {
                    <div className="pagination-location">
                        Page {humanPageNumber} of {numberOfPages}
                    </div>
                }
                {nextPagePath && <Link to={nextPagePath} rel="next"><MdKeyboardArrowRight /></Link>}
            </div>
        </nav>
    )
}

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
}

export default Pagination
