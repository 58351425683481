import React from 'react'
import PropTypes from 'prop-types'
import FooterLinksItem from './FooterLinksItem'

const FooterLinksList = ({ footerLists }) => (
    <>
        {footerLists.map((list, i) => {
            if (list.listItems) {
                return (
                    <div>
                        <h4>{list.listTitle}</h4>
                        <ul key={i}>
                            {list.listItems.map((listItem, i) => (
                                <FooterLinksItem
                                    label={listItem.label}
                                    url={listItem.url} 
									target={listItem.target}
                                    key={i}
                                />
                            ))}
                        </ul>
                    </div>
                )
            }
        })}
    </>
)

FooterLinksList.propTypes = {
    footerLists: PropTypes.any,
}

export default FooterLinksList
