import React from 'react'
import PropTypes from 'prop-types'

const DropDownMenuItem = ({ url, icon, iconAltText, label, description }) => {
    if (label === 'Chat with an Expert') {
        return (
            <a className="dropdown-item" href={url} target="_blank" rel="noopener noreferrer">
                <span>
                    <img src={icon} alt="" />
                </span>
                <span>
                    {label}
                    <p>{description}</p>
                </span>
            </a>
        )
    } else {
        return (
            <a className="dropdown-item" href={url}>
                <span>
                    <img src={icon} alt="" />
                </span>
                <span>
                    {label}
                    <p>{description}</p>
                </span>
            </a>
        )
    }
}
DropDownMenuItem.propTypes = {
    url: PropTypes.string,
    icon: PropTypes.string,
    iconAltText: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
}
export default DropDownMenuItem