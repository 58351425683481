import React from 'react'
import PropTypes from 'prop-types'
import NavLogo from '../navigation/NavLogo'
import NavContent from '../navigation/NavContent'

import iconADOIntegration from '../../images/nav-icons/icon-ado-integration.svg'
import iconAPIIntegration from '../../images/nav-icons/icon-api-integration.svg'
import iconJiraIntegration from '../../images/nav-icons/icon-jira-integration.svg'
import iconChat from '../../images/nav-icons/icon-chat.svg'
import iconFeatures from '../../images/nav-icons/icon-features.svg'
import iconGuides from '../../images/nav-icons/icon-guides.svg'
import iconLearning from '../../images/nav-icons/icon-learning.svg'
import iconKnowledge from '../../images/nav-icons/icon-knowledge.svg'
import iconPodcast from '../../images/nav-icons/icon-podcast.svg'
import iconSecurity from '../../images/nav-icons/icon-security.svg'
import iconOurRoadmap from '../../images/nav-icons/icon-our-roadmap.svg'
import iconBlog from '../../images/nav-icons/icon-blog.svg'

const Navigation = ({ navItems, site, data }) => (
    <nav className="site-nav masthead" role="navigation">
        <div className="nav-container flex align-center">
            <NavLogo
                site={site}
                data={data}
            />
            <NavContent 
                navItems={navItems}
            />
        </div>
    </nav>
)

Navigation.defaultProps = {
    navItems: [
        {
            label: 'Product',
            url: '',
            dropDownMenuItems: [
                {
                    label: 'Features',
                    description: 'Capture customer feedback, prioritize product ideas, and use roadmaps to communicate your strategy.',
                    url: 'https://roadmunk.com/features/product-roadmap-tool',
                    icon: iconFeatures,
                },
                {
                    label: 'Jira roadmap integration',
                    description: 'Connect your high-level strategy with day-to-day planning by syncing your data between Roadmunk and Jira.',
                    url: 'https://roadmunk.com/jira-roadmap-integration',
                    icon: iconJiraIntegration,
                },
                {
                    label: 'Azure DevOps integration',
                    description: 'Easily synchronize work items and field data into a strategic roadmap.',
                    url: 'https://roadmunk.com/azure-devops-roadmap-integration',
                    icon: iconADOIntegration,
                },
                {
                    label: 'Roadmunk API',
                    description: 'Sync your roadmapping data with the tools your team uses to get work done.',
                    url: 'https://roadmunk.com/api-integration',
                    icon: iconAPIIntegration,
                },
                {
                    label: 'Security',
                    description: 'Roadmunk takes security seriously so you can rest assured your data is safe.',
                    url: 'https://roadmunk.com/security',
                    icon: iconSecurity,
                },
            ],
        },
        {
            label: 'Templates',
            url: 'https://roadmunk.com/roadmap-templates',
        },
        {
            label: 'Customers',
            url: 'https://roadmunk.com/customers',
        },
		{
            label: 'Enterprise',
            url: 'https://roadmunk.com/enterprise-solutions',
        },
        {
            label: 'Plans & Pricing',
            url: 'https://roadmunk.com/pricing',
        },
        {
            label: 'Learn & Support',
            url: '',
            dropDownMenuItems: [
				{
                    label: 'Guides & eBooks',
                    description: 'Read our in depth guides on all things planning, prioritizng and roadmapping.',
                    url: 'https://roadmunk.com/guides',
                    icon: iconGuides,
                },
				{
                    label: 'Product Management Blog',
                    description: 'Get the latest insights on product management and roadmapping trends.',
                    url: 'https://roadmunk.com/product-management-blog',
                    icon: iconBlog,
                },
				{
                    label: 'Learning Center',
                    description: 'Master Roadmunk from the ground up with the collection of user resources.',
                    url: '/',
                    icon: iconLearning,
                },
				{
                    label: 'Glossary',
                    description: 'View our glossary which contains the most common definitions and terminology in our space.',
                    url: 'https://roadmunk.com/glossary',
                    icon: iconGuides,
                },
				{
                    label: 'Knowledge Base',
                    description: 'A collection of in-depth documentation on all things Roadmunk.',
                    url: 'https://support.roadmunk.com/hc/en-us',
                    icon: iconKnowledge,
                },
            ],
        },
    ],
}

Navigation.propTypes = {
    navItems: PropTypes.any,
    site: PropTypes.any,
    data: PropTypes.any,
    label: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.any,
    iconAltText: PropTypes.string,
}

export default Navigation
