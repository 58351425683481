import React from 'react'
import PropTypes from 'prop-types'

const FooterLinksItem = ({ label, url, target }) => (
    <li>
        <a 
            href={url} 
			target={target}>
            {label}
        </a>
    </li>
)
FooterLinksItem.propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
}
export default FooterLinksItem
