import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const SiteBanner = ({ siteTitle, siteDescription, HeaderImage }) => (
    <div className="site-banner">
        <div>
            <h1 className="site-banner-title">{siteTitle}</h1>
            <p className="site-banner-desc">{siteDescription}</p>
            <div className="site-banner-quick-links">
                <span>Jump to: </span>
                <Link to="#onboarding">Onboarding & Training</Link>
                <span> | </span>
                <Link to="#spotlight">Feature Spotlight</Link>
                <span> | </span>
                <Link to="/product-updates">Product Updates</Link>
            </div>
        </div>
        <img className="site-banner-image hide-xs" src={HeaderImage} alt="books beside computer" />
    </div>
)

SiteBanner.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
    HeaderImage: PropTypes.string,
}

export default SiteBanner