import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/roadmunk-logo.svg'
import FooterLinksList from './FooterLinksList'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa'

const year = new Date().getFullYear();

const Footer = ({ footerLists }) => (
    <footer>
        <div className="footer-content">
            <div className="footer-logo">
                <img src={logo} alt="Roadmunk" />
                <p>Roadmunk is the best roadmapping tool for building and communicating your product strategy.</p>
            </div>
            <div className="footer-links">
                <FooterLinksList
                    footerLists={footerLists}
                />
            </div>
        </div>
        <div className="footer-social">
            <div className="info-left">
                <p>&#169; Roadmunk {year}</p>
                <a href="https://roadmunk.com/terms-and-conditions">Terms & Conditions</a>
                <a href="https://roadmunk.com/privacy-policy">Privacy Policy</a>
                <a href="https://roadmunk.com/security">Security</a>
            </div>
            <div className="info-right">
                <a href="https://www.youtube.com/channel/UCNNonqvuVtObfi8Ktsh8n2A" target="blank" rel="noreferrer noopener">
                    <FaYoutube />
                </a>
                <a href="https://twitter.com/RoadmunkApp" target="blank" rel="noreferrer noopener">
                    <FaTwitter />
                </a>
                <a href="https://www.facebook.com/roadmunkapp/" target="blank" rel="noreferrer noopener">
                    <FaFacebookF />
                </a>
                <a href="https://www.linkedin.com/company/roadmunk" target="blank" rel="noreferrer noopener">
                    <FaLinkedinIn />
                </a>
            </div>
        </div>
    </footer>
)

Footer.defaultProps = {
    footerLists: [
        {
            listTitle: 'Company',
            listItems: [
                {
                    label : 'About us',
                    url   : 'https://roadmunk.com/company',
					target: '',
                },
                {
                    label : 'Contact us',
                    url   : 'https://roadmunk.com/company#contact',
					target: '',
                },
                {
                    label : 'Open Positions',
                    url   : 'https://www.tempo.io/open-positions',
					target: '',
                },
                {
                    label : 'Customers',
                    url   : 'https://roadmunk.com/customers',
					target: '',
                },
            ],
        },
        {
            listTitle: 'Product',
            listItems: [
                {
                    label : 'Capture Feedback',
                    url   : 'https://roadmunk.com/features/customer-feedback',
					target: '',
                },
                {
                    label : 'Prioritize Ideas',
                    url   : 'https://roadmunk.com/features/idea-prioritization',
					target: '',
                },
                {
                    label : 'Build a Roadmap',
                    url   : 'https://roadmunk.com/features/product-roadmap-tool',
					target: '',
                },
                {
                    label : 'Azure DevOps Integration',
                    url   : 'https://roadmunk.com/azure-devops-roadmap-integration',
					target: '',
                },
                {
                    label : 'Roadmunk API',
                    url   : 'https://roadmunk.com/api-integration',
					target: '',
                },
				{
                    label : 'Jira Integration',
                    url   : 'https://roadmunk.com/jira-roadmap-integration',
					target: '',
                },
				{
                    label : 'Jira Time Tracking',
                    url   : 'https://marketplace.atlassian.com/apps/6572/timesheets-by-tempo-jira-time-tracking?tab=overview&hosting=cloud',
					target: '_blank',
                },
				{
                    label : 'Jira Portfolio Management',
                    url   : 'https://marketplace.atlassian.com/apps/34717/structure-by-tempo-jira-portfolio-management-ppm?tab=overview&hosting=cloud',
					target: '_blank',
                },
				{
                    label : 'Jira Capacity Planning',
                    url   : 'https://marketplace.atlassian.com/apps/1211881/planner-by-tempo-resource-planning-capacity-planning?tab=overview&hosting=cloud',
					target: '_blank',
                },
            ],
        },
        {
            listTitle: 'Resources',
            listItems: [
				{
                    label : 'Blog',
                    url   : 'https://roadmunk.com/product-management-blog',
					target: '',
                },
                {
                    label : 'Guides & eBooks',
                    url   : 'https://roadmunk.com/guides',
					target: '',
                },
                {
                    label : 'Learning Center',
                    url   : 'https://learn.roadmunk.com',
					target: '',
                },
                {
                    label : 'Glossary',
                    url   : 'http://roadmunk.com/glossary/',
					target: '',
                },{
                    label : 'Knowledge Base',
                    url   : 'http://support.roadmunk.com/',
					target: '',
                },
                {
                    label : 'Product Updates',
                    url   : 'https://learn.roadmunk.com/product-updates',
					target: '',
                },
				{
                    label : 'Email Newsletter',
                    url   : 'https://roadmunk.com/newsletter',
					target: '',
                },
            ],
        },
        {
            listTitle: 'Roadmap Templates',
            listItems: [
                {
                    label : 'Product Roadmap',
                    url   : 'https://roadmunk.com/roadmap-templates/product-roadmap',
					target: '',
                },
                {
                    label : 'Technology Roadmap',
                    url   : 'https://roadmunk.com/roadmap-templates/technology-roadmap',
					target: '',
                },
                {
                    label : 'Project Roadmap',
                    url   : 'https://roadmunk.com/roadmap-templates/project-roadmap',
					target: '',
                },
                {
                    label : 'Agile Roadmap',
                    url   : 'https://roadmunk.com/roadmap-templates/agile-roadmap',
					target: '',
                },
                {
                    label : 'Portfolio Roadmap',
                    url   : 'https://roadmunk.com/roadmap-templates/portfolio-roadmap',
					target: '',
                },
            ],
        },
    ],
}
Footer.propTypes = {
    footerLists: PropTypes.array,
}

export default Footer
