import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ href, className, buttonText }) => (
    <a href={href} className={className}>
        {buttonText}
    </a>
)

Button.propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    buttonText: PropTypes.string,
}

export default Button