import React from 'react'
import PropTypes from 'prop-types'
import Button from '../common/Button'
import NavList from './NavList'

const NavContent = ({ navItems }) => (
    <div className="nav-content">
        <input type="checkbox" id="nav-menu" />
        <label htmlFor="nav-menu">
            <span className="icon-menu screen-reader-only">Menu</span>
        </label>
        <ul className="nav-list nav-left nav-mobile">
            <NavList
                navItems={navItems}
            />
        </ul>
        <ul className="nav-list nav-right">
            <li className="nav-item nav-button">
                <Button
                    href="https://login.roadmunk.com/"
                    className="button button-login"
                    buttonText="Log in"
                />
            </li>
            <li className="nav-item nav-button">
                <Button
                    href="https://login.roadmunk.com/signup"
                    className="button button-signup"
                    buttonText="Try it free"
                />
            </li>
        </ul>
    </div>
)
NavContent.propTypes = {
    navItems: PropTypes.array,
}

export default NavContent