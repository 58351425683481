import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/roadmunk-logo.svg'

const NavLogo = ({ site, data }) => (
    <div className="nav-logo logo-full">
        <a href="https://roadmunk.com">
            {Boolean(site.logo) &&
                <img className="site-logo" src={logo} alt={site.title} />
            }
        </a>
    </div>
)
NavLogo.propTypes = {
    site: PropTypes.object,
}

export default NavLogo
