import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import DropDownMenuItem from './DropDownMenuItem'
import DropDownArrow from '../../images/nav-icons/arrow-down.svg'

const NavList = ({ navItems }) => {   
    // const [menuState, setMenuState] = useState(false)
    const [navLinksMuted, setNavLinksMuted] = useState(false)

    const muteAllNavLinks = () => {
        setNavLinksMuted(true)
    }
    const unmuteAllNavLinks = () => {
        setNavLinksMuted(false)
    }

    return (
        <>
            {navItems.map((navItem, i) => {
                if (navItem.dropDownMenuItems) {
                    return (
                        <li
                            className="nav-item nav-dropdown"
                            key={i}
                            onMouseOver={muteAllNavLinks}
                            onMouseOut={unmuteAllNavLinks}
                        >
                            <a href="#" className={navLinksMuted ? 'muted' : ''}>
                                {navItem.label}
                            </a>
                            <img className="dropdown-arrow" src={DropDownArrow} />
                            <div className="nav-dropdown-menu">
                                {navItem.dropDownMenuItems.map((dropDownMenuItem, i) => (
                                    <DropDownMenuItem
                                        url={dropDownMenuItem.url}
                                        icon={dropDownMenuItem.icon}
                                        label={dropDownMenuItem.label}
                                        description={dropDownMenuItem.description}
                                        key={i}
                                    />
                                ))}
                            </div>
                        </li>
                    )
                } else {
                    return <li
                        className="nav-item"
                        key={i}
                        onMouseOver={muteAllNavLinks}
                        onMouseOut={unmuteAllNavLinks}
                    >
                        <a
                            className={navLinksMuted ? 'muted' : ''}
                            href={navItem.url}
                            key={i}>{navItem.label}
                        </a>
                    </li>
                }
            })}
        </>
    )
}
NavList.propTypes = {
    navItems: PropTypes.any,
}

export default NavList